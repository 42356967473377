import { VBottomNavigation } from 'vuetify/lib/components/VBottomNavigation';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"main-wrapper"},[_c('video-background',{staticStyle:{"max-height":"400px","height":"100vh"},attrs:{"src":'/wiki-background.webm',"poster":'/Snapshot_3.png',"autoplay":true,"sources":[
      {
        src: '/wiki-background-2.mp4',
        res: 800,
        autoplay: true,
        poster: '/vlcsnap-00003.png',
      },
    ],"overlay":"linear-gradient(45deg,#2a4ae430,#fb949e6b)"}},[_c('h1',{staticStyle:{"align-self":"center","color":"white"}},[_vm._v("Conocimiento para el Exito. ")]),_c(VBottomNavigation,{staticStyle:{"position":"absolute","bottom":"0","background-color":"transparent","color":"#fff","padding-top":"0px"},attrs:{"grow":"","flat":"","color":"white"}},[_c(VBtn,{attrs:{"href":"#glosary","value":"glosary"}},[_c('span',{staticStyle:{"color":"white"}},[_vm._v("Glosario")]),_c(VIcon,[_vm._v("mdi-history")])],1),_c(VBtn,{attrs:{"href":"#conocimientos","value":"knowledge"}},[_c('span',{staticStyle:{"color":"white"}},[_vm._v("Conocimientos")]),_c(VIcon,[_vm._v("mdi-heart")])],1),_c(VBtn,{attrs:{"value":"statistics"}},[_c('span',{staticStyle:{"color":"white"}},[_vm._v("Estadisticas")]),_c(VIcon,[_vm._v("mdi-map-marker")])],1),_c(VBtn,{attrs:{"value":"podcasts"}},[_c('span',{staticStyle:{"color":"white"}},[_vm._v("Podcast")]),_c(VIcon,[_vm._v("mdi-map-marker")])],1),_c(VBtn,{attrs:{"value":"forms"}},[_c('span',{staticStyle:{"color":"white"}},[_vm._v("Encuestas")]),_c(VIcon,[_vm._v("mdi-map-marker")])],1)],1)],1),_c('knowledge',{staticStyle:{"padding-top":"80px","margin":"0 50px"},attrs:{"id":"conocimientos","videos":_vm.videoCarousel}}),_c('glosary',{staticStyle:{"margin-bottom":"20px","padding-top":"80px","height":"calc(100vh - 70px)"},attrs:{"id":"glosary"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }