<template>
  <v-row no-gutters style="width: 100%">
    <!-- SEARCH AND TITLE -->
    <v-row no-gutters class="search__container">
      <h6 style="margin: 0px; padding-left: 50px; font-size: 1.5rem">
        Glosario de Térmios
      </h6>
      <div class="col-4 col-sm-4">
        <multiselect
          style="padding-left: 20px"
          v-model="search"
          :custom-label="resultName"
          track-by="id"
          placeholder="¿Qué estas buscando?"
          open-direction="bottom"
          :options="searchResult"
          :multiple="false"
          :searchable="true"
          :loading="searching"
          :internal-search="false"
          :clear-on-select="false"
          :close-on-select="true"
          :options-limit="300"
          :limit="3"
          :max-height="300"
          :show-no-results="false"
          :hide-selected="true"
          @search-change="asyncFind"
          @select="selectedOption($event)"
        >
        </multiselect>
      </div>
    </v-row>
    <!-- / SEARCH AND TITLE -->

    <v-row no-gutters style="width: 100%; height: 70vh; padding: 0 50px">
      <div class="offset-md-4 col-md-8">
        <letter-pagination
          style="padding: 0"
          :sliderColor="mainColor"
          :sliderSize="letterSliderSize"
          @updateLetter="handleLetterChange($event)"
        />
      </div>
      <v-row no-gutters style="height: calc(100% - 60px); width: 100%">
        <!-- CATEGORIES SECTION -->
        <div class="col-md-4" style="overflow-y: auto; height: calc(100% - 100px)">
          <keep-alive>
            <v-list class="categories" style="padding: 0" tag="li">
              <v-list-item-group v-model="categoryId">
                <v-list-item
                  :value="category.id"
                  class="categories-tab"
                  exact-active-class="categories-tab--active"
                  v-for="category in categories"
                  :key="category.id"
                  @click="handleCategoryChange(category.id)"
                >
                  <v-list-item-title>{{ category.name }}</v-list-item-title>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </keep-alive>
        </div>

        <!-- WORDS SECTION -->
        <keep-alive>
          <v-row no-gutters v-if="wordsLoading">
            <v-progress-circular
              style="margin: auto"
              size="40"
              indeterminate
              :color="mainColor"
            ></v-progress-circular>
          </v-row>
          <v-row
            no-gutters
            v-else-if="wordsEmpty"
            style="align-items: center; justify-content: center"
          >
            {{ wordsEmptyText }}
          </v-row>
          <v-list
            v-else
            class="words-container col-md-8"
            style="overflow-y: auto; height: calc(100% - 100px)"
          >
            <v-list-item-group>
              <v-list-item active-class="active-item">
                <v-expansion-panels>
                  <v-expansion-panel v-for="(word, id) in words" :key="id">
                    <v-expansion-panel-header>
                      {{ word.title }}
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-card-text class="">
                        <v-row style="align-items: center">
                          <v-chip
                            class="m-1"
                            color="blue"
                            outlined
                            v-for="(category, id) in word.categories"
                            :key="id"
                          >
                            {{ category.name }}
                          </v-chip>
                        </v-row>
                        <v-divider></v-divider>
                        <p class="content__text mb-2" v-html="word.description"></p>
                        <v-row
                          style="justify-content: center"
                          v-if="word.uploads.length > 0"
                        >
                          <v-col
                            class="d-flex child-flex"
                            cols="3"
                            v-for="(image, id) in word.uploads"
                            :key="id"
                          >
                            <v-img
                              :src="image.route"
                              aspect-ratio="1"
                              class="grey lighten-2"
                            >
                              <template v-slot:placeholder>
                                <v-row
                                  class="fill-height ma-0"
                                  align="center"
                                  justify="center"
                                >
                                  <v-progress-circular
                                    indeterminate
                                    color="grey lighten-5"
                                  ></v-progress-circular>
                                </v-row>
                              </template>
                            </v-img>
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-list-item>
            </v-list-item-group>
            <v-pagination
              v-if="wordsPage > 1"
              v-model="wordsPage"
              class="pg-btn"
              color="#daad1b"
              :length="wordsPages"
              style="margin: 20px 0"
              @input="changePage($event)"
            ></v-pagination>
          </v-list>
        </keep-alive>
      </v-row>
    </v-row>
  </v-row>
</template>
<script>
  import LetterPagination from '@/components/globalFrontendComponents/WikiLetterPagination'
  export default {
    name: 'WikiGlosary',
    data() {
      return {
        search: '',
        searchResult: [],
        searching: false,
        mainColor: '#ffc60b',
        letter: '',
        letterSliderSize: 4,
        categories: [],
        categoryId: 0,
        categoriesLoading: false,
        words: [],
        wordsPage: 1,
        wordsPages: 5,
        wordsTotal: 1,
        wordsPerPage: 5,
        wordsLoading: false,
        wordsLoadingText: '',
        wordsEmpty: false,
        wordsEmptyText: '',
      }
    },
    components: {
      LetterPagination,
    },
    created() {
      this.getCategories()

      this.getWords()
    },

    methods: {
      async getCategories() {
        axios
          .get(
            process.env.VUE_APP_API_DIRECTORY +
              'categories?type=4&order=name&by=asc&limit=100'
          )
          .then((response) => {
            this.categories = response.data
            if (this.categories.length < 1) {
              this.categoriesLoadingText = 'No hay registros'
            }
            this.categoriesLoading = false
          })
      },
      async getWords() {
        this.wordsLoading = true
        this.wordsLoadingText = 'Buscando Términos'
        let queryParams = ''

        let categoryString = ''
        let letterString = ''

        if (this.categoryId != 0) {
          categoryString = '&category_id=' + this.categoryId
        }
        if (this.letter != '') {
          letterString = '&letter=' + this.letter
        }
        queryParams = categoryString + letterString
        // }
        axios
          .get(
            process.env.VUE_APP_API_DIRECTORY +
              'words?active=1&order=title&by=asc&page=' +
              this.wordsPage +
              queryParams
          )
          .then((response) => {
            this.words = response.data.data
            if (this.words.length < 1) {
              this.wordsEmpty = true
              this.wordsEmptyText = 'No hay términos... por ahora.'
            } else {
              this.wordsEmpty = false
            }
            this.wordsTotal = response.data.total
            this.wordsPerPage = response.data.per_page
            if (response.data.from) {
              this.wordsPage = response.data.page
              this.wordsPages = response.data.last_page
            } else {
              this.wordsPage = 1
              this.wordsPages = 1
            }
            this.wordsLoading = false
          })
      },
      selectedOption(option) {
        if ((option.model = 'category')) {
          this.handleCategoryChange(option.id)
        } else if ((option.model = 'word')) {
          this.words = [option]
        }
      },
      handleCategoryChange(categoryId) {
        this.categoryId = categoryId
        this.letter = ''
        this.getWords()
      },
      handleLetterChange(letter) {
        this.letter = letter
        this.categoryId = 0
        this.getWords()
      },
      async changePage(page) {
        this.wordsPage = page
        await this.getWords()
      },
      asyncFind(query) {
        this.searching = true
        if (query && query != '') {
          axios
            .get(
              process.env.VUE_APP_API_DIRECTORY +
                'category-management/search-categories-and-words?search=' +
                query
            )
            .then((response) => {
              this.searchResult = response.data
              this.searching = false
            })
        } else {
          this.searching = false
        }
      },
      resultName({ name, model }) {
        if (name != '' && model === 'word') {
          return `${name} (Término)`
        } else if (name != '' && model === 'category') {
          return `${name} (Categoría)`
        } else {
          return ''
        }
      },
    },
  }
</script>
<style lang="scss">
  :root {
    --main-color: #ffc60b;
  }
  /* Estilos del buscador del glosario*/
  .search__container {
    height: 100px;
    padding: 10px;
    align-items: center;
    background: linear-gradient(to right, var(--main-color), white) 0% / 70% no-repeat;
  }

  /* Estilos de la columna de categorias del glosario*/

  .categories-tab.categories-tab.categories-tab.categories-tab {
    justify-content: normal;
    // color: black;
    p {
      margin: 0;
    }
  }
  .categories-tab.categories-tab {
    p {
      color: rgba(0, 0, 0, 0.54);
      font-weight: 500;
    }
  }
  .categories-tab--active.categories-tab--active {
    p {
      color: black;
      font-weight: 500;
    }
  }

  .words-container::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 10px;
  }
  .words-container::-webkit-scrollbar-thumb {
    background-color: var(--main-color);
    border-radius: 20px;
    border: 2px solid #f1f2f3;
  }
</style>
